<!--
 * @Author: wangtianyu 499398000@qq.com
 * @Date: 2023-12-14 12:37:25
 * @LastEditors: wangtianyu 499398000@qq.com
 * @LastEditTime: 2023-12-19 09:36:54
 * @FilePath: \1-lmt-ru-fe\src\views\components\home\modalLogin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    :title="isLogin ? 'Вход' : 'Регистрация'"
    :visible="visible"
    :footer="null"
    class="login-modal"
    @cancel="handleCancel"
  >
    <template v-if="isLogin">
      <login @success="handleCancel" />
      <div class="register-text">
        <span>Нет аккаунта？</span
        ><span class="register-red" @click="() => (isLogin = false)"
          > Регистрация</span
        >
      </div>
    </template>
    <template v-else>
      <register @success="handleCancel" />
      <div class="register-text">
        <span>У вас уже есть аккаунт？</span
        ><span class="register-red" @click="() => (isLogin = true)"
          >Вернитеся к входу </span
        >
      </div>
    </template>
  </a-modal>
</template>
<script>
import Register from "./register";
import Login from "./login";

export default {
  name: "ModalLogin",
  components: {
    Register,
    Login,
  },
  data() {
    return {
      visible: false,
      isLogin: true,
    };
  },
  methods: {
    openModal(isLogin) {
      this.isLogin = !!isLogin;
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
  },
  created() {
    // getTradeAmount({ tradeRange: 2 }).then((res) => {
    //   console.log(res);
    // });
  },
};
</script>
