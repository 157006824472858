/*
 * @Author: wangtianyu 499398000@qq.com
 * @Date: 2023-12-11 10:25:39
 * @LastEditors: wangtianyu 499398000@qq.com
 * @LastEditTime: 2023-12-14 17:58:35
 * @FilePath: \1-lmt-ru-fe\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'lib-flexible'
import '@/utils/rem.js'
Vue.config.productionTip = false

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd)

// 引入全局样式
import '@/assets/css/global.less'

import { VueAxios } from './utils/request'
Vue.use(VueAxios)

import VueStorage from 'vue-ls'
Vue.use(VueStorage)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
