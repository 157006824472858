import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout/layout.vue'


// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: layout,
    children:[
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue')
      },
      {
        path: '/ljguide',
        name: 'ljguideContainer',
        component: () => import('@/views/ljguide.vue')
      },
      {
        path: '/ljimpression',
        name: 'ljimpression',
        component: () => import('@/views/ljImpression.vue')
      },
      {
        path: '/ljopportunity',
        name: 'ljopportunity',
        component: () => import('@/views/ljOpportunity.vue')
      },
      {
        path: '/ljcity',
        name: 'ljcity',
        component: () => import('@/views/ljCity.vue')
      },
      {
        path: '/cargoods',
        name: 'cargoodsPage',
        component: () => import('@/views/cargoodsPage.vue')
      },
      {
        path: '/sale',
        name: 'sale',
        component: () => import('@/views/sale/index.vue')
      },
      {
        path: '/quickOrder',
        name: 'quickOrder',
        component: () => import('@/views/international/index.vue')
      },
      {
        path: '/RUCNexposition',
        name: 'RUCNexposition',
        component: () => import('@/views/bannerPage/RUCNexposition.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
