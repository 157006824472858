var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{staticClass:"form-login",attrs:{"form":_vm.registerForm,"wrapper-col":{ span: 24 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            { rules: [{ required: true, message: 'Введите адрес электронной почты' }] },
          ]),expression:"[\n            'email',\n            { rules: [{ required: true, message: 'Введите адрес электронной почты' }] },\n          ]"}],attrs:{"placeholder":"Электронная почта"},on:{"change":_vm.handleInputChange}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'verifyCode',
                { rules: [{ required: true, message: 'Введите графический проверочный код' }] },
              ]),expression:"[\n                'verifyCode',\n                { rules: [{ required: true, message: 'Введите графический проверочный код' }] },\n              ]"}],attrs:{"placeholder":"Капча с картинкой"},on:{"change":_vm.handleInputChange}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"safety"},slot:"prefix"})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('img',{staticClass:"verify-code",attrs:{"src":_vm.captchaData.img}})]),_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"change-img-code",on:{"click":_vm.changeImgCode}},[_vm._v("Не видно？")])])],1)],1),_c('a-form-item',[_c('a-row',[_c('a-col',{attrs:{"span":10}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'emailCode',
                { rules: [{ required: true, message: 'Введите проверочный код электронной почты' }] },
              ]),expression:"[\n                'emailCode',\n                { rules: [{ required: true, message: 'Введите проверочный код электронной почты' }] },\n              ]"}],attrs:{"placeholder":"Код подтверждения электронной почты"},on:{"change":_vm.handleInputChange}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"safety"},slot:"prefix"})],1)],1),_c('a-col',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"span":14}},[_c('a-button',{staticClass:"button-send-email",attrs:{"type":"link","disabled":_vm.isSend},on:{"click":_vm.sendEmailCaptcha}},[_vm._v(" "+_vm._s(_vm.captchaButtonName)+" ")])],1)],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            { rules: [{ required: true, message: 'Введите пароль' }] },
          ]),expression:"[\n            'password',\n            { rules: [{ required: true, message: 'Введите пароль' }] },\n          ]"}],attrs:{"placeholder":"Пароль"},on:{"change":_vm.handleInputChange}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1),_c('a-checkbox',{staticClass:"register-text",on:{"change":_vm.onChangeAgreement}},[_vm._v(" Я прочитал/ла и согласен/сна ")]),_c('span',{staticClass:"register-red",on:{"click":_vm.handleShowClause}},[_vm._v(" Условия предоставления услуг, Политика конфиденциальности")]),(_vm.errorMes)?_c('a-alert',{attrs:{"message":_vm.errorMes,"banner":""}}):_vm._e(),_c('a-button',{staticClass:"register-submit-btn",on:{"click":_vm.handleRegister}},[_vm._v(" Регистрация ")]),_c('div',{staticClass:"register-text"})],1),_c('Clause',{ref:"refClause"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }