<template>
  <div class="login-container">
    <a-spin :spinning="loading">
      <a-form
        :form="registerForm"
        :wrapper-col="{ span: 24 }"
        class="form-login"
      >
        <a-form-item>
          <a-input
            v-decorator="[
              'email',
              { rules: [{ required: true, message: 'Введите адрес электронной почты' }] },
            ]"
            placeholder="Электронная почта"
            @change="handleInputChange"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-row>
            <a-col :span="12">
              <a-input
                v-decorator="[
                  'verifyCode',
                  { rules: [{ required: true, message: 'Введите графический проверочный код' }] },
                ]"
                placeholder="Капча с картинкой"
                @change="handleInputChange"
              >
                <a-icon
                  slot="prefix"
                  type="safety"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-col>
            <a-col :span="6">
              <img :src="captchaData.img" class="verify-code" />
            </a-col>
            <a-col :span="6">
              <div class="change-img-code" @click="changeImgCode">Не видно？</div>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item>
          <a-row>
            <a-col :span="10">
              <a-input
                v-decorator="[
                  'emailCode',
                  { rules: [{ required: true, message: 'Введите проверочный код электронной почты' }] },
                ]"
                placeholder="Код подтверждения электронной почты"
                @change="handleInputChange"
              >
                <a-icon
                  slot="prefix"
                  type="safety"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-col>
            <a-col :span="14" style="display: flex;justify-content: center;">
              <a-button
                class="button-send-email"
                type="link"
                :disabled="isSend"
                @click="sendEmailCaptcha"
              >
                {{ captchaButtonName }}
              </a-button></a-col
            >
          </a-row>
        </a-form-item>
        <a-form-item>
          <a-input
            v-decorator="[
              'password',
              { rules: [{ required: true, message: 'Введите пароль' }] },
            ]"
            placeholder="Пароль"
            @change="handleInputChange"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-form>
      <a-checkbox class="register-text" @change="onChangeAgreement">
        Я прочитал/ла и согласен/сна
      </a-checkbox>
      <span class="register-red" @click="handleShowClause">
        Условия предоставления услуг, Политика конфиденциальности</span
      >
      <a-alert v-if="errorMes" :message="errorMes" banner />

      <a-button class="register-submit-btn" @click="handleRegister">
        Регистрация
      </a-button>
      <div class="register-text">
        <!-- <div v-if="!isRegister">
        <span>还没有账号？</span
        ><span class="register-red" @click="register">立即注册</span>
      </div>
      <div v-else>
        <span>已有账号？</span
        ><span class="register-red" @click="register">返回登录</span>
      </div> -->
      </div>
    </a-spin>
    <Clause ref="refClause"></Clause>
  </div>
</template>
<script>
import { getImageCaptcha, sendEmailCaptcha, registerByEmail } from "@/api/home";
import Clause from './clause'

const RESET_TIME = 10;

export default {
  name: "RegisterComponent",
  components: {
    Clause
  },
  data() {
    return {
      registerForm: this.$form.createForm(this, { name: "registerForm" }),
      // isRegister: true,
      loading: false,
      isChecked: false,
      captchaData: {
        uuid: "",
        img: "",
      },
      errorMes: "",
      captchaButtonName: "Отправить проверочный код",
      isSend: false,
      totalTime: RESET_TIME,
      timer: null,
    };
  },
  methods: {
    handleInputChange() {
      this.errorMes = undefined;
    },
    /* 更新图形验证码 */
    changeImgCode() {
      getImageCaptcha()
        .then((res) => {
          const { uuid, img } = res.data;
          this.captchaData.uuid = uuid;
          this.captchaData.img = img;
        })
        .catch(() => {});
    },
    /* 发送邮箱验证码 */
    sendEmailCaptcha() {
      const email = this.registerForm.getFieldValue("email");
      const verifyCode = this.registerForm.getFieldValue("verifyCode");
      if (!(email && verifyCode)) {
        this.errorMes = "Пожалуйста, проверьте правильность ввода номера мобильного телефона и графического проверочного кода";
      } else {
        const params = {
          email,
          verifyCode,
          imgUuid: this.captchaData.uuid,
        };
        sendEmailCaptcha(params).then((res) => {
          if (!(res.status === 100)) {
            this.errorMes = res.message;
            this.$message.error(res.message);
          } else {
            this.getResetBtn();
            this.errorMes = "";
            this.$message.success(res.message);
          }
        });
      }
    },
    onChangeAgreement(e) {
      this.isChecked = e.target.checked;
    },
    handleRegister() {
      if (!this.isChecked) {
        this.$message.error("Пожалуйста, сначала прочтите и согласитесь с условиями предоставления услуг");
      } else {
        this.registerForm.validateFields((errors, values) => {
          if (errors) return;
          const { email, emailCode, password } = values;
          const params = {
            email,
            emailCode,
            password,
          };
          this.loading = true;
          registerByEmail(params).then((res) => {
            if (!(res.status === 100)) {
              this.$message.error(res.message);
            } else {
              this.$message.success(res.message);
            }
            this.loading = false;
            this.$emit('success')
          }).catch(() => {
            this.loading = false;
          });
        });
      }
    },
    /* 验证码按钮倒计时 */
    getResetBtn() {
      this.errorMes = undefined;
      this.isSend = true;
      this.captchaButtonName = this.totalTime + "sОтправить повторно позже";
      this.timer = setInterval(() => {
        this.totalTime--;
        this.captchaButtonName = this.totalTime + "sОтправить повторно позже";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.captchaButtonName = "Повторно отправьте проверочный код";
          this.totalTime = RESET_TIME;
          this.isSend = false;
        }
      }, 1000);
    },
    handleShowClause() {
      this.$refs['refClause'].initModal()
    },
  },
  created() {
    this.changeImgCode();
  },
};
</script>
<style lang="less"></style>
