<template>
  <div class="layoutContainer page-wrap">
    <headerContainer></headerContainer>
    <div class="content">
      <RouterView/>
    </div>
    <footerContainer></footerContainer>
  </div>
</template>

<script>
import headerContainer from '@/layout/headerContainer.vue'
import footerContainer from '@/layout/footerContainer.vue'
export default {
  name: 'layoutContainer',
  components: {
    headerContainer,
    footerContainer,
}
}
</script>
<style lang="less" scoped>
  .layoutContainer{
    height: 100%;
    background-color: #f5f5f5;
    .content{
      width: 100%;
      min-height: calc(100vh - 575px);
      background-color: #fff;
      display: flex;
    }
  }
</style>
