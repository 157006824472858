/*
 * @Author: wangtianyu 499398000@qq.com
 * @Date: 2023-12-11 10:25:39
 * @LastEditors: wangtianyu 499398000@qq.com
 * @LastEditTime: 2023-12-29 14:32:28
 * @FilePath: \1-lmt-ru-fe\src\api\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from "@/utils/request";

/**
 * 首页获取贸易统计数据
 */
export function getTradeAmount(parameter) {
  return axios({
    url: "/portal/tradeAmount",
    method: "get",
    params: parameter,
  });
}

/**
 * 首页-我要卖列表
 */
export function getSupplyList() {
  return axios({
    url: "/portal/supplyList?size=4",
    method: "get",
  });
}
/**
 * 首页-我要买列表
 */
export function getDemandList() {
  return axios({
    url: "/portal/demandList?size=4",
    method: "get",
  });
}

/**
 * 供应详情页
 */
export function getSupplyDetail(parameter) {
  return axios({
    url: "/portal_supply/detail",
    method: "get",
    params: parameter,
  });
}
/**
 * 需求详情页
 */
export function getDemandDetail(parameter) {
  return axios({
    url: "/portal_demand/detail",
    method: "get",
    params: parameter,
  });
}

/**
 * 获取图形验证码
 */
export function getImageCaptcha() {
  return axios({
    baseURL: process.env.VUE_APP_API_USER_PREFIX,
    url: `/user/captcha`,
    method: "get",
  });
}
/**
 * 发送邮箱验证码
 */
export function sendEmailCaptcha(params) {
  return axios({
    baseURL: process.env.VUE_APP_API_USER_PREFIX,
    url: `/user/emailCode`,
    method: "post",
    data: params,
  });
}
/**
 * 用户注册--邮箱
 */
export function registerByEmail(params) {
  return axios({
    baseURL: process.env.VUE_APP_API_USER_PREFIX,
    url: `/user/register`,
    method: "post",
    data: params,
  });
}
/**
 * 用户登录
 */
export function loginByEmail(params) {
  return axios({
    baseURL: process.env.VUE_APP_API_USER_PREFIX,
    url: `/user/login`,
    method: "post",
    data: params,
  });
}
/**
 * 首页-京东-汽车商品查询
 */
export function getCarsGoodsList(params) {
  return axios({
    url: "/goods/pageGoods",
    method: "post",
    data: params,
  });
}

export function getAuthExpireLogin(parameter) {
  return axios({
    url: '/portal/tradeAmount',
    method: 'get',
    params: parameter
  })
}
/**
 * 境外快速下单
 */
export function quickOrderOversea(parameter) {
  return axios({
    url: '/portal_quick_order/saveFn',
    method: 'post',
    data: parameter
  })
}

/**
 * 快速下单图形验证码
 */
export function getImgCode(parameter) {
  return axios({
    url: '/portal_login/getImgCode',
    method: 'get',
    params: parameter
  })
}

/**
 * 忘记密码
 */
export function forgetByEmail(parameter) {
  return axios({
    baseURL: process.env.VUE_APP_API_USER_PREFIX,
    url: '/user/retrievePwd',
    method: 'post',
    data: parameter
  })
}