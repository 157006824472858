/*
 * @Author: tgaotina tgao_tina@163.com
 * @Date: 2023-07-21 16:39:37
 * @LastEditors: wangtianyu 499398000@qq.com
 * @LastEditTime: 2023-12-14 10:42:27
 * @FilePath: \longtrade-portal\lanif-ui\src\store\getters.js
 * @Description:
 */
const getters = {
  userName: state => state.user.name,
  portalsUserLogin: state => state.portalsUser.islogin,
  portalsInfo: state => state.portalsUser.portalsInfo,
  authFlag: state => state.portalsUser.authFlag
}

export default getters
