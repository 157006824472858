// import Vue from 'vue'
import { loginByEmail } from "@/api/home";
import store from "../index";

const user = {
  state: {
    token: "",
    name: "",
    welcome: "",
    avatar: "",
    buttons: [], //按钮权限
    admintype: "", //是否是超管
    roles: [],
    info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons;
    },
    SET_ADMINTYPE: (state, admintype) => {
      state.admintype = admintype;
    },
  },

  actions: {
    // 登录
    userLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByEmail(userInfo)
          .then((response) => {
            if (!(response.status === 100)) {
              reject(response.message);
              return;
            }
            const result = response.data;
            const expires = Date.now() + 1000 * 60 * 60 * 24;
            localStorage.setItem("token", result.token);
            localStorage.setItem("userName", result.email);
            localStorage.setItem("expires", expires);
            commit("SET_TOKEN", result.token);
            commit("SET_NAME", result.email);
            store.commit("SET_ISLOGIN", true);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    // GetInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     getLoginUser()
    //       .then(response => {
    //         const data = response.data
    //         if (response.success) {
    //           commit('SET_ADMINTYPE', data.adminType)
    //           commit('SET_ROLES', 1)
    //           commit('SET_BUTTONS', data.permissions)
    //           commit('SET_INFO', data)
    //           commit('SET_NAME', { name: data.name, welcome: welcome() })
    //           commit('SET_AVATAR', data.avatar)
    //           resolve(data)
    //         } else {
    //           reject(new Error(data.message))
    //         }
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // },

    // 登出
    userLogout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem("token");
        localStorage.removeItem("userName");
        localStorage.removeItem("expires");
        commit("SET_TOKEN", "");
        commit("SET_NAME", "");
        store.commit("SET_ISLOGIN", false);
        resolve(true);
      });
    },

    // 修改密码
    // UpdatePwd({ commit }, passwords) {
    //   return new Promise((resolve, reject) => {
    //     sysUserUpdatePwd(passwords)
    //       .then(res => {
    //         resolve(res)
    //         return
    //       })
    //       .catch(() => {
    //         resolve()
    //       })
    //   })
    // }
  },
};

export default user;
