<template>
  <a-modal
    dialog-class="login-clause"
    :visible="visible"
    :body-style="bodyStyle"
    :mask-closable="false"
    :footer="null"
    @cancel="handleCancel"
  >
    <template slot="title">
      <!-- 服务条款 -->
      <div class="clause-title">Соглашение об оказании услуг</div>
    </template>

    <div class="clause-content">
      <label
        ><!--[if gte mso 9
          ]><xml
            ><w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="true"
            DefSemiHidden="true" DefQFormat="false" DefPriority="99"
            LatentStyleCount="260" > <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Normal" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="heading 1" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="heading 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="heading 3" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="heading 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="heading 5" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="heading 6" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="heading 7" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="heading 8" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="heading 9" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="index 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="index 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="index 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="index 4" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="index 5" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="index 6" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="index 7" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="index 8" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="index 9" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="toc 1" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="toc 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="toc 3" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="toc 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="toc 5" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="toc 6" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="toc 7" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="toc 8" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="toc 9" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Normal Indent"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="footnote text" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="annotation text" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="header"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="footer" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="index heading"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="caption" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="table of
            figures" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="envelope address"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="envelope return" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="footnote reference" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="annotation
            reference" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="line number"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="page number" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="endnote reference" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="endnote text"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="table of authorities" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="macro" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="toa heading"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="List" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="List Bullet"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="List Number" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="List 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List 4" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="List 5" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List Bullet 2" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="List Bullet 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="List Bullet 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List Bullet 5" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="List Number 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="List Number 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List Number 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="List Number 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Title" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Closing"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Signature" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Default Paragraph Font" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Body Text"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Body Text Indent" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List Continue" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="List Continue
            2" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="List Continue 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List Continue 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="List Continue
            5" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Message Header" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Subtitle" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Salutation"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Date" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Body Text
            First Indent" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Body Text First Indent 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Note Heading" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Body Text 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Body Text 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Body Text Indent 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Body Text Indent 3" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Block Text"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Hyperlink" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="FollowedHyperlink" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Strong"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Emphasis" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Document Map" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Plain Text"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="E-mail Signature" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Normal (Web)" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="HTML Acronym"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="HTML Address" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="HTML Cite" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="HTML Code" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="HTML Definition" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="HTML Keyboard"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="HTML Preformatted" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="HTML Sample" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="HTML Typewriter"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="HTML Variable" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Normal Table" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="annotation
            subject" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="No List" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="1 / a / i" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="1 / 1.1 / 1.1.1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Article / Section" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Simple 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Simple
            2" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Simple 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Classic 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Classic
            2" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Classic 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Classic 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Colorful
            1" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Colorful 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Colorful 3" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Columns
            1" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Columns 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Columns 3" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Columns
            4" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Columns 5" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Grid 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Grid 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Grid 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Grid 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Grid 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Grid 6" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Grid 7" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Grid 8"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table List 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table List 2" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table List 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table List 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table List 5" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table List 6"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table List 7" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table List 8" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table 3D
            effects 1" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Table 3D effects 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table 3D effects 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Contemporary" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Elegant"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Professional" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Subtle 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Table Subtle
            2" ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Table Web 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Web 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Table Web 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Balloon Text" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Table Grid" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Table Theme"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Placeholder Text" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="No Spacing" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Shading"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Light List" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Grid" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Shading 1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium List 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium Grid 2" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Dark List" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Colorful Shading" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful List"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Shading Accent 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Light List
            Accent 1" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Grid Accent 1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 1 Accent 1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2 Accent 1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium List 1 Accent 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="List Paragraph" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Quote"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Intense Quote" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 2 Accent 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 1
            Accent 1" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Grid 2 Accent 1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 3 Accent 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Dark List Accent 1" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful
            Shading Accent 1" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Colorful List Accent 1"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid Accent 1" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Shading Accent 2" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Light List
            Accent 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Grid Accent 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 1 Accent 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2 Accent 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium List 1 Accent 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 2 Accent 2" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 1
            Accent 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Grid 2 Accent 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 3 Accent 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Dark List Accent 2" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful
            Shading Accent 2" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Colorful List Accent 2"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid Accent 2" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Shading Accent 3" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Light List
            Accent 3" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Grid Accent 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 1 Accent 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2 Accent 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium List 1 Accent 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 2 Accent 3" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 1
            Accent 3" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Grid 2 Accent 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 3 Accent 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Dark List Accent 3" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful
            Shading Accent 3" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Colorful List Accent 3"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid Accent 3" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Shading Accent 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Light List
            Accent 4" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Grid Accent 4"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 1 Accent 4"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2 Accent 4"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium List 1 Accent 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 2 Accent 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 1
            Accent 4" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Grid 2 Accent 4"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 3 Accent 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Dark List Accent 4" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful
            Shading Accent 4" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Colorful List Accent 4"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid Accent 4" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Shading Accent 5" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Light List
            Accent 5" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Grid Accent 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 1 Accent 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2 Accent 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium List 1 Accent 5" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 2 Accent 5" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 1
            Accent 5" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Grid 2 Accent 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 3 Accent 5" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Dark List Accent 5" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful
            Shading Accent 5" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Colorful List Accent 5"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid Accent 5" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Light Shading Accent 6" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Light List
            Accent 6" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Light Grid Accent 6"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 1 Accent 6"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Shading 2 Accent 6"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium List 1 Accent 6" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Medium List 2 Accent 6" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Medium Grid 1
            Accent 6" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Medium Grid 2 Accent 6"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Medium Grid 3 Accent 6" ></w:LsdException>
            <w:LsdException Locked="false" Priority="99" SemiHidden="false"
            Name="Dark List Accent 6" ></w:LsdException> <w:LsdException
            Locked="false" Priority="99" SemiHidden="false" Name="Colorful
            Shading Accent 6" ></w:LsdException> <w:LsdException Locked="false"
            Priority="99" SemiHidden="false" Name="Colorful List Accent 6"
            ></w:LsdException> <w:LsdException Locked="false" Priority="99"
            SemiHidden="false" Name="Colorful Grid Accent 6" ></w:LsdException>
            </w:LatentStyles></xml
          ><!
        [endif]-->
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Добро пожаловать на ознакомление с</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">о </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">Соглашение</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">м</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">об оказании услуг </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">комплексной</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">платформы </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">обслуживания </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">международной </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">логистики и </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">торговли </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">между Китаем и Россией</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;"</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">LONG TRADE</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >" (</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >далее именуемым “настоящее
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">С</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">оглашение”).</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Условия предоставления услуг, содержащиеся в настоящем
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">C</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >оглашении, применяются к различным услугам, предоставляемым этой
              платформой пользователям.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 15.75pt; mso-char-indent-count: 1.5"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">Комплексная</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">платформ</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">а</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">обслуживания </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">международной </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">логистики и </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">торговли </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">между Китаем и Россией</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;(</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">далее именуемая “</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">LONG TRADE</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">”</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >) создана Heilongjiang Communications Investment International
            Materials Trading Group Co., Ltd</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">в</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >соответствии с планом стратегического развития комитета
              Коммунистической партии Китая и правительства в провинции
              Хэйлунцзян для активного создания
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">комплексной</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">платформ</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ы</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">обслуживания </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">международной </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">логистики и </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">торговли </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">между Китаем и Россией</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, построени</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">я</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >цифровой цепочки стоимости в китайско-российской торговле и
              реализаци</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">и</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >“российской торговли</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">в один клик”.</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅰ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Описание услуги</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Платформа и оператор платформы соглашаются предоставлять
            сопутствующие услуги на основе сетей Интернета и мобильной связи
            (далее именуемые “сетевые услуги”) в соответствии с положениями
            настоящего соглашения и периодически издаваемыми правилами
            эксплуатации (далее именуемые “сетевые услуги”). В целях получения
            сетевых услуг пользователи сервиса (далее именуемые "пользователи")
            должны согласиться со всеми условиями настоящего соглашения и
            выполнить все процедуры регистрации в соответствии с подсказками на
            странице.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Нажимая кнопку “Согласен” в процессе регистрации, пользователь
              полностью принимает все условия настоящего соглашения, включая
              принятие любых изменений, внесенных данным веб-сайтом в условия
              предоставления услуг.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Если пользователь успешно зарегистрировался и предоставил
            правдивую, точную и полную информацию о пользователе в соответствии
            с требованиями платформы, пользователь получит регистрационный номер
            и соответствующий пароль. Вы должны сохранять этот веб-сайт и
            связанные с ним пароли в безопасности. Любое использование </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунта </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">для входа </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">э</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">того веб-сайта должн</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">о</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >рассматриваться как ваша собственная операция, а электронные
              информационные записи, сгенерированные в результате операции,
              являются действительными учетными данными для поведения
              пользователей услуг этого веб-сайта; пользователи несут
              юридическую ответственность за все действия и события,
              осуществляемые с использованием их пользовательских номеров.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >3. После успешной регистрации пользователя, в процессе
            использования сервисов данной платформы, данная платформа имеет
            право проводить некоммерческие обследование и изучение, основанные
            на операционном поведении пользователя.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅱ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Инструкция по применению</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Когда пользователи подают заявку на использование сетевых
            сервисов платформы, они должны предоставить платформе достоверную и
            эффективную информацию о пользователях в соответствии с
            требованиями. Если в </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">предоставленных </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">информации</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">пользователями</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >есть какие-либо изменения, они должны быть своевременно
              обновлены.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Пользователи не должны использовать услуги данного веб-сайта для
            загрузки, передачи или иного распространения любого контента,
            который является незаконным, дискредитирующим, навязчивым,
            оскорбительным, мошенническим, непристойным, содержит вирусы или на
            который будут поданы жалобы другими способами в соответствии с
            разумным суждением данного веб-сайта.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Пользователи не должны загружать, передавать или иным образом
              распространять контент, нарушающий права интеллектуальной
              собственности или другие имущественные, договорные или кредитные
              права или обязательства других сторон; не должны использовать этот
              сервис в каких-либо мошеннических или ненадлежащих целях;
              извлекать или иным образом собирать информацию о других лицах,
              включая адреса электронной почты, без согласия пользователя.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >3. Пользователи должны следовать следующим принципам в процессе
            использования сетевых сервисов данной платформы<font face="宋体"
              >：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">① </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">Соблюдать </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">соответствующ</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ие</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">китайски</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">е</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">закон</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ы</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">и нормативн</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ые</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">акт</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ы</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="宋体">；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >② Соблюдать все сетевые протоколы, правила и процедуры, связанные
              с сетевыми сервисами</font
            ><font face="宋体">；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >③ Вы не должны использовать систему сетевого обслуживания этой
              платформы в каких - либо незаконных целях</font
            ><font face="宋体">；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >④ Не используйте систему сетевого обслуживания данной платформы
              для выполнения каких - либо действий, которые могут негативно
              повлиять на нормальную работу Интернета или мобильной сети</font
            ><font face="宋体">；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >⑤ Вы не должны использовать систему сетевого обслуживания
              платформы для совершения каких - либо действий, которые не
              способствуют работе платформы</font
            ><font face="宋体">；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >4. Любые заявления, уведомления, предупреждения и т.д., сделанные
            этой платформой различными способами (включая, но не ограничиваясь,
            веб-объявлениями, электронной почтой, sms-напоминаниями и т.д.) для
            использования определенных сетевых сервисов платформы, считаются
            частью настоящего соглашения. Если пользователь использует такие
            сетевых сервисов платформы, считается, что пользователь согласен с
            содержанием таких заявлений, уведомлений и предупреждений.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >5. Право собственности на </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунт</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >на этом веб-сайте принадлежит данному веб-сайту. После того, как
              пользователь завершит регистрацию
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунта</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, он/она получает право использовать соответствующ</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ий</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунт</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, и соответствующее право на использование принадлежит только
            субъекту регистрации </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">и </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аутентификации.</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунта. </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Пользователи не могут передавать или сдавать в аренду свои
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунты</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >и другие права на использование сервиса.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если платформа обнаруживает или имеет разумные основания
              полагать, что пользователь
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунта </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >не является первоначальным владельцем</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">аккаунта</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >или что имеет место какая-либо ненадлежащая регистрация или
              ненадлежащее использование, в целях защиты безопасности
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунта</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >платформа имеет право немедленно приостановить или прекратить
              предоставление услуг зарегистрированному пользователю</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >и имеет право навсегда отключить
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">данный аккаунт.</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >6. Пользователи не должны активно информировать третьих лиц о
            сво</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">их аккаунтах</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >или предоставлять их третьим лицам для использования.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если в результате этого произойдет утечка информации о
              конфиденциальности или экономические потери других пользователей,
              а также потеря веб-сайта, пользователь несет полную
              ответственность.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >7. Пользователь подтверждает, что заполненный контент и информация,
            загруженная через этот веб-сайт, являются правдивыми, законными и
            действительными, в противном случае, если веб-сайт и третья сторона
            понесут убытки из-за ошибки </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">данного пользователя</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, пользователь соглашается нести ответственность за причиненный
            этим ущерб (включая, но не ограничиваясь гонорарами юриста, судебные
            издержки, арбитражные сборы и другие разумные расходы).</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Пользователь подтверждает и разрешает вышеуказанному контенту и
              материалам, что данный веб-сайт может храниться в течение
              длительного времени и использоваться в разумных целях без
              получения разрешения пользователя или уплаты каких-либо сборов
              пользователю, за исключением случаев, предусмотренных законами и
              нормативными актами, такими как личная информация.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >8. Если пользователь нарушает национальные законы и нормативные
            акты или настоящие условия предоставления услуг, платформа будет
            иметь право прекратить предоставление услуг пользователю, не
            принимая на себя никакой ответственности. Если этому веб-сайту будет
            нанесен какой-либо ущерб или возникнут какие-либо споры, судебные
            иски, претензии и т.д. от третьих лиц, пользователь обязан
            возместить ущерб платформу за соответствующие убытки, включая, но не
            ограничиваясь штрафами, компенсациями, гонорарами адвокатов,
            судебными или арбитражными сборами и другими необходимыми расходами,
            и несет полную юридическую ответственность за все последствия,
            вытекающие из нарушения ею условий предоставления услуг.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅲ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Защита конфиденциальности</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Защита конфиденциальности пользователей является основной
            политикой этой платформы. Эта платформа гарантирует, что она не
            будет раскрывать общественности или предоставлять третьим лицам
            регистрационную информацию отдельных пользователей и непубличный
            контент, хранящийся на этой платформе, когда пользователи пользуются
            сетевыми сервисами, за исключением информации, собранной платформой,
            которая позволяет платформе сообщать пользователям о последних
            анонсах продуктов платформы, обновлениях программного обеспечения и
            предстоящих событиях.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Использование и раскрытие информации: Платформа имеет право
            получать, использовать, хранить и передавать личную информацию
            пользователей явным образом при условии соблюдения законов и
            нормативных актов.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Платформа не будет раскрывать, редактировать или разглашать
              личную информацию пользователя и непубличный контент, хранящийся
              пользователем на платформе, без разрешения пользователя.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Пользователь соглашается и гарантирует, что данный веб-сайт имеет
              право собирать и использовать информацию, относящуюся к
              пользователю, в соответствии с правилами защиты личной информации,
              сформулированными платформой.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅳ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Права интеллектуальной собственности</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Все права на интеллектуальную собственность сетевых сервисов,
            предоставляемых данной платформой, принадлежат оператору платформы.
            Без письменного разрешения оператора платформы пользователям не
            разрешается осуществлять обратное проектирование</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="宋体">（</font
            ><font face="Times New Roman">reverseengineer</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, декомпиляцию<font face="宋体">（</font
            ><font face="Times New Roman">decompile</font
            ><font face="宋体">） </font
            ><font face="Times New Roman">или дизассемблирование </font
            ><font face="宋体">（</font
            ><font face="Times New Roman">disassemble</font
            ><font face="宋体">）</font
            ><font face="Times New Roman">сетевых сервисов.</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Все материалы или контент, такие как товарные знаки</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">доменны</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">х</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >имен, текстовый, видео- и звуковой контент, графика и
              изображения, содержащиеся на этом веб-сайте, защищены законами и
              нормативными актами; за исключением контента, защищенного
              авторским правом пользователя в соответствии с законом, никто не
              может перепечатывать, копировать, распространять, переиздавать,
              воспроизводить, подключать или передавать по суперссылке,
              загружать на другие серверы "зеркальным способом", сохранять в
              информационно-поисковой системе или использовать в любой другой
              форме без предварительного письменного разрешения соответствующей
              стороны, такой как этот веб-сайт.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >3. Права интеллектуальной собственности на исходную информацию,
            такую как текст, изображения, видео, программное обеспечение и
            выступления, опубликованные и загруженные пользователями,
            использующими этот веб-сайт и сервисы при использовании сервисов
            платформы, принадлежат пользователю (или иным образом согласованы
            сторонним поставщиком контента и пользователем), но основаны на
            потребност</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">и</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >функционирования данного веб-сайта, пользователь подтверждает,
              что публикация и загрузка такой информации должны рассматриваться
              как право на использование, копирование и распространение прав
              интеллектуальной собственности, разрешенных платформой.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >4. Жалобы или сообщения о содержании информации.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если законные права и интересы пользователя нарушаются во время
              использования сервисов данного веб-сайта, пользователь имеет право
              уведомить платформу для принятия необходимых мер по борьбе с
              этим.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если пользователь обнаружит, что во время использования данного
              веб-сайта имеет место незаконное нарушение или несоблюдение
              соответствующих правил сервиса, пользователь также имеет право
              сообщить платформе, и платформа незамедлительно примет необходимые
              меры (удалит, заблокирует, отключит ссылки или ограничит
              использование функций, и т.д.), чтобы справиться с этим.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >5. Разрешение на защиту прав на контент.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >В той мере, в какой это разрешено законами и нормативными актами,
              пользователь соглашается и уполномочивает платформу предпринимать
              любые формы юридических действий против действий, которые нарушают
              законные права и интересы пользователей (включая, но не
              ограничиваясь, частное копирование, использование, редактирование,
              плагиат и т.д.), включая, но не ограничиваясь, жалобы, судебные
              иски и другие необходимые меры по защите прав.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅴ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Сервисные риски и заявление об освобождении от
              ответственности</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Платформа не несет ответственности за перебои в обслуживании
            сети или другие дефекты, вызванные форс-мажорными обстоятельствами
            или причинами, не зависящими от платформы, но сделает все возможное,
            чтобы уменьшить убытки и последствия, причиненные
            пользователям</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">из-за этого</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Данная платформа не несет ответственности за любые убытки,
            понесенные при использовании услуг данной платформы по собственным
            причинам пользователя или его уполномоченного представителя.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >3. Как только пользователь получает доступ, регистрируется или
            использует эту платформу, ее подсистемы или другие связанные системы
            (следующая платформа содержит ее подсистемы или все связанные
            системы), это означает, что пользователь понимает, соглашается и
            принимает все заявления, условия, уведомления, анонсы и т.д. этой
            платформы.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >4. Информация, отображаемая на этой платформе, предоставляется
            самими сторонами транзакции или другими субъектами, и издатель
            информации несет ответственность за ее подлинность, точность,
            полноту и законность. Эта платформа не предоставляет никаких
            гарантий или обещаний и не берет на себя никакой
            ответственности.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >5. Стороны </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">сделки</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >или любого другого поведения, осуществляемого через эту
              платформу, несут ответственность за
            </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">сделки </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">или другое поведение.</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >В случае возникновения спора стороны</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">сделки</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >или другие субъекты также несут ответственность</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman">за это, э</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >та платформа не предоставляет никаких гарантий или обещаний и не
              берет на себя никакой ответственности.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >6. Данная платформа не несет ответственности за проблемы платформы,
            такие как прерывание сетевого обслуживания, вызванное форс-мажорными
            обстоятельствами или причинами, не связанными с платформой.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >7. Дружеское напоминание: В целях защиты законных прав и интересов
            пользователей, пожалуйста, тщательно и разумно избегайте связанных с
            этим рисков при любом поведении, таком как публикация информации или
            торговля на этой платформе.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅵ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Компенсация за нарушение договора</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Если платформа нарушает соответствующие законы, нормативные акты
            или какие-либо условия настоящего соглашения и причиняет убытки
            пользователям, платформа соглашается нести ответственность за
            причиненный этим ущерб.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Если платформа обнаружит или получит сообщение или жалобу от
            других лиц о том, что пользователь нарушил настоящие условия
            предоставления услуг или законы, нормативные акты и соответствующие
            национальные нормативные акты, платформа имеет право принять меры по
            предупреждению, замораживанию, прекращению/приостановке/ограничению
            использования и других функций, отменить или расторгнуть настоящие
            Условия службы или предлагать такие меры, как компенсация
            ущерба.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >3. Пользователь соглашается защищать интересы платформы и других
            пользователей. Если пользователь нарушает соответствующие законы,
            нормативные акты или любые условия настоящего соглашения и причиняет
            убытки платформе или любой другой третьей стороне, пользователь
            соглашается нести ответственность за причиненный этим ущерб.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >4. После прекращения действия настоящих условий предоставления
            услуг платформа не обязана раскрывать какую-либо информацию в </span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">аккаунте</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >пользователя пользователю или третьей стороне, указанной
              пользователем, за исключением случаев, прямо предусмотренных
              законом.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >После прекращения действия настоящих условий предоставления услуг
              платформа по-прежнему пользуется следующими правами: в
              соответствии с положениями закона она будет продолжать хранить все
              виды информации, хранящейся пользователем на платформе; за прошлые
              нарушения пользователем условий контракта платформа по-прежнему
              может привлечь пользователя к ответственности за нарушение
              договора в соответствии с настоящими условиями предоставления
              услуг.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >VII. Внесение изменений в соглашение</span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >1. Эта платформа имеет право изменять любые условия настоящего
            соглашения. Как только содержание настоящего соглашения изменится,
            эта платформа напрямую опубликует пересмотренное содержание
            соглашения в форме онлайн-объявления или напоминания на этой
            платформе</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >, данная опублиция</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >считается тем, что платформа уведомила пользователя о измен</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">ении</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">контента</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Платформа также может уведомлять пользователей об измененном
              контенте платформы другими подходящими способами.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >2. Если вы не согласны с изменениями, внесенными данной платформой
            в соответствующие условия настоящего соглашения, пользователь имеет
            право прекратить использование сетевых сервисов.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если пользователь продолжит пользоваться сетевыми сервисами,
              будет считаться, что пользователь принимает изменения, внесенные
              платформой в соответствующие условия настоящего соглашения.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >3. В соответствии с настоящим соглашением, все уведомления,
            отправляемые платформой пользователям, могут быть сделаны
            посредством веб-объявлений, электронной почты, SMS-сообщений на
            мобильный телефон или обычной доставки писем; такие уведомления
            считаются доставленными получателю в день доставки.</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅷ</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              >. Правовая юрисдикция</span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Заключение, применение, толкование и разрешение споров, связанных
              с настоящими условиями, регулируются законами Китайской Народной
              Республики.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если между двумя сторонами возникает какой-либо спор по поводу
              содержания сервиса или его работы, обе стороны должны сделать все
              возможное, чтобы разрешить его путем дружественных
              переговоров;</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman">b </font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="Times New Roman"
              >если переговоры не увенчаются успехом, любая из сторон может
              подать иск в народный суд по месту нахождения ответчика.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 宋体;
              mso-ascii-font-family: 'Times New Roman';
              mso-hansi-font-family: 'Times New Roman';
              mso-bidi-font-family: 'Times New Roman';
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            >&nbsp;</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если некоторые положения настоящего пункта являются
              недействительными из-за противоречия действующему законодательству
              Китайской Народной Республики, это не повлияет на действительность
              других положений.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21.1pt; mso-char-indent-count: 2"
        >
          <b
            ><span style="font-family: 宋体; font-size: 10.5pt"
              ><font face="宋体">Ⅸ </font
              ><font face="Times New Roman">C</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><font face="Times New Roman">вяжитесь с нами</font></span
            ></b
          ><b
            ><span style="font-family: 'Times New Roman'; font-size: 10.5pt"
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="text-indent: 21pt; mso-char-indent-count: 2"
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><font face="Times New Roman"
              >Если у вас есть какие-либо вопросы, жалобы, комментарии и
              предложения по поводу наших услуг, вы можете связаться с нами для
              получения обратной связи.</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-size: 10.5pt;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p></p
      ></label>
    </div>
  </a-modal>
</template>
<script>
export default {
  name: "LoginClause",
  data() {
    return {
      bodyStyle: {
        height: "70vh",
        overflowY: "auto",
      },
      visible: false,
    };
  },
  methods: {
    initModal() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
<style lang="less">
.login-clause {
  .clause-title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .clause-content {
    p {
      line-height: 1.5 !important;
      span {
        font-size: 14px !important;
      }
    }
  }
}
</style>
