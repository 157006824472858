import { render, staticRenderFns } from "./clause.vue?vue&type=template&id=028bf2d0"
import script from "./clause.vue?vue&type=script&lang=js"
export * from "./clause.vue?vue&type=script&lang=js"
import style0 from "./clause.vue?vue&type=style&index=0&id=028bf2d0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports