/*
 * @Author: wangtianyu 499398000@qq.com
 * @Date: 2023-12-11 10:00:11
 * @LastEditors: wangtianyu 499398000@qq.com
 * @LastEditTime: 2023-12-14 10:23:04
 * @FilePath: \1-lmt-ru-fe\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import portalsUser from './modules/portalUser'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    portalsUser
  }
})
