<template>
  <div class="login-container">
    <a-spin :spinning="loading">
      <a-form :form="loginForm" :wrapper-col="{ span: 24 }" class="form-login">
        <a-form-item>
          <a-input
            v-decorator="[
              'email',
              { rules: [{ required: true, message: 'Введите адрес электронной почты' }] },
            ]"
            placeholder="Электронная почта"
            @change="handleInputChange"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-row>
            <a-col :span="12">
              <a-input
                v-decorator="[
                  'verifyCode',
                  { rules: [{ required: true, message: 'Введите графический проверочный код' }] },
                ]"
                placeholder="Капча с картинкой"
                @change="handleInputChange"
              >
                <a-icon
                  slot="prefix"
                  type="safety"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-col>
            <a-col :span="6">
              <img :src="captchaData.img" class="verify-code" />
            </a-col>
            <a-col :span="6">
              <div class="change-img-code" @click="changeImgCode">Не видно？</div>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item>
          <a-input-password 
            v-decorator="[
              'password',
              { rules: [{ required: true, message: 'Введите пароль' }] },
            ]"
            placeholder="Пароль"
            @change="handleInputChange"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-form-item>
      </a-form>
      <a-alert v-if="errorMes" :message="errorMes" banner />
      <a-button class="register-submit-btn" @click="handleLogin">
        Войти
      </a-button>
    </a-spin>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { getImageCaptcha } from "@/api/home";
export default {
  name: "LoginComponent",
  data() {
    return {
      loginForm: this.$form.createForm(this, { name: "loginForm" }),
      captchaData: {
        uuid: "",
        img: "",
      },
      errorMes: undefined,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["userLogin"]),
    /* 更新图形验证码 */
    changeImgCode() {
      getImageCaptcha()
        .then((res) => {
          const { uuid, img } = res.data;
          this.captchaData.uuid = uuid;
          this.captchaData.img = img;
        })
        .catch(() => {});
    },
    handleInputChange() {
      this.errorMes = undefined;
    },
    handleLogin() {
      this.loginForm.validateFields((errors, values) => {
        if (!errors) {
          const { email, password, verifyCode } = values;
          this.loading = true;
          const params = {
            email: email,
            password: password,
            imgUUID: this.captchaData.uuid,
            verifyCode: verifyCode,
          };
          this.userLogin(params)
            .then(() => {
              this.loading = false;
              this.$emit("success");
            })
            .catch((e) => {
              this.$message.error(e);
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.changeImgCode();
  },
};
</script>
<style lang="less" scoped>
.login-container {
  width: 100%;
  .form-login {
    width: 100%;
    /deep/.ant-input-affix-wrapper .ant-input {
      border: none;
    }
    /deep/.ant-input:focus {
      border-color: none;
    }
    /deep/.ant-input:focus {
      border: none;
      box-shadow: none;
    }
    /deep/.ant-form-item {
      margin-bottom: 17px;
      height: 50px;
      border-bottom: 1px solid #dcdcdc;

      height: 50px;
    }
    /deep/.ant-form-item-control {
      line-height: 50px;
    }
    /deep/.ant-form-explain {
      text-align: left;
    }
    .verify-code {
      height: 40px;
    }
    .change-img-code {
      font-size: 12px;
      color: var(--themeColor);
      cursor: pointer;
    }
  }
  .register-submit-btn {
    margin-top: 20px;
    background-color: var(--themeColor);
    width: 100%;
    color: white;
    height: 40px;
    border: none;
  }
  .register-text {
    text-align: center;
    margin-top: 12px;

    .register-red {
      color: var(--themeColor);
      cursor: pointer;
    }
  }
}
</style>
