/*
 * @Author: wangtianyu 499398000@qq.com
 * @Date: 2023-12-11 10:25:39
 * @LastEditors: wangtianyu 499398000@qq.com
 * @LastEditTime: 2023-12-18 15:03:59
 * @FilePath: \1-lmt-ru-fe\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import Vue from 'vue'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import store from '@/store'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_PREFIX, // api base_url
  timeout: 30000 // 请求超时时间
})
const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = window.localStorage.getItem(ACCESS_TOKEN)
    if (error.response.status === 403) {
      console.log('服务器403啦，要重新登录！')

    }
    if (error.response.status === 500) {
      if (data.message.length > 0) {
        // Message.error(data.message)
      }
    }
    if (error.response.status === 404) {
      if (data.message.length > 0) {
        // Message.error(data.message)
      }
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      // Notification.error({
      //   message: 'Unauthorized',
      //   description: 'Authorization verification failed'
      // })
      // Notification({
      //   title: '警告',
      //   message: 'Authorization verification failed',
      //   type: 'warning'
      // })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = window.localStorage.getItem(ACCESS_TOKEN)
  if (token) {
    config.headers['AuthorizationRU'] = token
  }
  return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use((response) => {
  if (response.request.responseType === 'blob') {
    return response
  }
  const code = response.data.code
  if (code === 403) {
    notification.error({
      message: 'Вход',
      description: response.data.message
    })
    store.dispatch('userLogout')
  } else if (code === 1013002 || code === 1016002 || code === 1015002) {
    return response.data
  } else {
    return response.data
  }
}, err)
const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
