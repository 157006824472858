<template>
  <div ref="footer" class="footer">
    <div class="footBanner">
      <img src="~@/assets/img/footer-banner.jpg" alt="" />
      <div class="title">ЦЕЛЬ РАЗВИТИЯ</div>
      <!-- 发展目标 -->
      <div class="slogan">Закупки по всему Китаю, для продаж по всей России,<br>закупки по всей России, для продаж по всему Китаю</div>
      <!-- 买全俄、卖全国、买全国、卖全俄 -->
      <div class="slogan_en">DEVELOPMENT GOAL</div>
    </div>
    <div class="footerInfo">
      <div class="footInfoContent">
        <ul class="aboutUs">
          <!-- 关于我们 -->
          <li class="title">О нас</li>
          <!-- 企业简介 -->
          <li class="aboutUsItem">О компании</li>
          <!-- 联系我们 -->
          <li class="aboutUsItem">Свяжитесь с нами</li>
          <img src="~@/assets/img/footer-line.png" alt="" />
        </ul>
        <ul class="contactUs">
          <!-- 联系我们 -->
          <li class="title">Свяжитесь с нами</li>
          <!-- 客服热线：400-668-6888 -->
          <li class="aboutUsItem">Горячая линия: +86400-668-6888</li>
          <!-- 公司地址：哈尔滨市松北区创新三路899号A栋14层 -->
          <li class="aboutUsItem">Адрес компании: 14-й этаж, корпус А, № 899, 3-я дорога Чжуансинь, район Сонбэй, г.Харбин  Китай</li>
        </ul>
        <ul class="qrList">
          <li class="qrItem">
            <img src="~@/assets/img/qrcode-mini-program.jpg" alt="" />
            <!-- 龙贸通小程序 -->
            <div class="qrTitle">Апплет<br>“LONG TRADE”</div>
          </li>
          <li class="qrItem">
            <img src="~@/assets/img/qrcode-official-accounts.jpg" alt="" />
            <!-- 龙贸通公众号 -->
            <div class="qrTitle">Публичный аккаунт<br>“LONG TRADE”</div>
          </li>
        </ul>
      </div>
      
    </div>
    <div class="footICP">
      <div class="logo">
        <img src="~@/assets/img/bottom-logo.png" alt="" />
      </div>
      <div class="icpInfo">
        黑ICP备2023008499号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
  .footer{
    width: 100%;
    .footBanner{
      width: 100%;
      height: 200px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }
      .title{
        width: 100%;
        text-align: center;
        font-size: 50px;
        line-height: 100px;
        font-weight: 700;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#c9c9c9), to(#eaeced));
        background-image: linear-gradient(to bottom, #b3b3b3, #ffffff);
        color: transparent;
        -webkit-background-clip: text;
        letter-spacing: 5px;
        position: absolute;
        left: 0;
        top: 20px;
        z-index: 1;
      }
      .slogan{
        width: 100%;
        line-height: 30px;
        font-size: 24px;
        color: #000;
        position: absolute;
        left: 0;
        top: 70px;
        z-index: 2;
        margin-bottom: 30px;
        &::before{
          position: absolute;
          left: 0;
          top:15px;
        }
        &::after{
          position: absolute;
          right: 0;
          top:15px;
        }
        &::after,&::before{
          display: block;
          content: '';
          width: 25%;
          height: 20px;
          background-color: #cc0a28;
        }
      }
      .slogan_en{
        width: 100%;
        line-height: 50px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 120px;
        font-weight: 600;
        color: #b3b3b3;
        font-size: 16px;
      }
    }
    .footerInfo{
      width: 100%;
      height: 235px;
      background-color: #35363d;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .footInfoContent{
        width: 80%;
        height: 235px;
        display: flex;
        align-items: center;
        .aboutUs,.contactUs{
          width: 200px;
          line-height: 30px;
          text-align: left;
          position: relative;
          .title{
            font-weight: 700;
          }
          .aboutUsItem{
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
            cursor: pointer;
          }
          img{
            position: absolute;
            top: -25px;
            right: 0;
          }
        }
        .contactUs{
          width: calc(100% - 480px);
          margin-left: 80px;
        }
        .qrList{
          display: flex;
          align-items: center;
          .qrItem{
            width: 130px;
            height: 100px;  
            margin-right: 40px;
            img{
              width: 100px;
              height: 100px;
            }
            .qrTitle{
              width: 100%;
              line-height: 30px;
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
    }
    .footICP{
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #cc0a28;
      padding: 0 10%;
      box-sizing: border-box;
      .logo{
        img{
          height: 40px;
        }
      }
      .icpInfo{
        color: #fff;
        font-size: 12px;
      }
    }
  }
</style>
