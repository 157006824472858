<template>
  <div class="headerContainer">
    <div class="page-wrap">
      <div class="logoBox">
        <img
          src="~@/assets/img/top-logo.png"
          class="top-logo"
          @click="jump('/')"
        />
      </div>
      <ul class="navlist">
        <li
          v-for="item in navList"
          :key="item.path"
          class="navItem"
          :class="[item.bg ? 'bg_red' : '', item.arrow ? 'arrow' : '']"
        >
          <template v-if="item.path === '/login'">
            <template v-if="portalsUserLogin">
              <a-dropdown placement="bottomCenter">
                <span>
                  {{ userName }}
                </span>
                <a-menu slot="overlay" class="menu">
                  <a-menu-item>
                    <a-popconfirm
                      title="Вы уверены, что хотите выйти из системы??"
                      ok-text="Подтверждать"
                      cancel-text="Отменять"
                      @confirm="confirm"
                      @cancel="cancel"
                    >
                      <a href="#">Выйти</a>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
            <template v-else>
              <span @click="showLogin">
                {{ item.name }}
              </span>
            </template>
          </template>
          <template v-else>
            <span @click="jump(item.path)">
              {{ item.name }}
            </span>
          </template>
        </li>
      </ul>
      <!-- <div class="red_block"></div> -->
    </div>
    <modal-login ref="modalLogin" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalLogin from "@/views/components/home/modalLogin";
export default {
  name: "headerContainer",
  components: {
    ModalLogin,
  },
  data() {
    return {
      navList: [
        {
          name: "Главная страница", //首页
          path: "/",
          bg: false,
          arrow: false,
        },
        {
          name: "Спрос и предложение", //我要买卖
          path: "/sale",
          bg: false,
          arrow: false,
        },
        {
          name: "Китайские автомобильные товары", //中国汽车商品
          path: "/cargoods",
          bg: false,
          arrow: false,
        },
        {
          name: "Быстрый заказ", //快速下单
          path: "/quickOrder",
          bg: false,
          arrow: false,
        },
        {
          name: "Вход/Регистрация", //登录/注册
          path: "/login",
          bg: true,
          arrow: true,
        },
        {
          name: "Китайский язык", //中文
          path: "http://www.longmaotong.com",
          bg: true,
          arrow: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userName", "portalsUserLogin"]),
  },
  methods: {
    ...mapActions(["userLogout"]),
    jump(path) {
      if (path.indexOf("http") >= 0) {
        window.open(path);
      } else {
        this.$router.push(path);
      }
    },
    confirm() {
      this.userLogout();
    },
    cancel() {},
    showLogin() {
      this.$refs.modalLogin.openModal(true);
    },
  },
  mounted() {
    const userName = localStorage.getItem("userName");
    const expires = localStorage.getItem("expires");
    if (userName) {
      if (Date.now() < expires) {
        this.$store.commit("SET_ISLOGIN", true);
        this.$store.commit("SET_NAME", userName);
      } else {
        this.$message.warning("Срок действия логина истек, войдите еще раз"); //登录已过期，请重新登录
        this.userLogout();
      }
    } else {
      this.userLogout();
    }
  },
};
</script>
<style lang="less" scoped>
.headerContainer {
  width: 100%;
  background: #35363d;
  .page-wrap {
    height: 80px;
    color: #fff;
    text-align: center;
    display: flex;
    line-height: 80px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: relative;
  }
  .logoBox {
    display: flex;
    margin-left: 30px;
    img {
      width: 140px;
      cursor: pointer;
    }
  }
  .navlist {
    width: calc(100% - 140px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    .navItem {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-right: -5px;
      padding: 0 32px;
      cursor: pointer;
      span {
        display: inline-block;
        line-height: 30px;
      }
      span:hover {
        border-bottom: 2px solid #fff;
      }
    }
    .bg_red {
      background-color: #cc0a28;
      position: relative;
    }
    .arrow {
      margin-left: 40px;
      width: 185px;
      &::after {
        display: block;
        content: "";
        width: 0;
        height: 0px;
        border-left: 40px solid transparent;
        border-bottom: 81px solid #cc0a28;
        position: absolute;
        left: -39px;
        top: 0;
      }
    }
  }
  .red_block {
    width: 10%;
    height: 80px;
    background-color: #cc0a28;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
