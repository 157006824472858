const portalsUser = {
  state: {
    islogin: false,
    portalsInfo: {},
    authFlag: ''
  },

  mutations: {
    SET_ISLOGIN: (state, islogin) => {
      state.islogin = islogin
    },
    SET_PORTALSINFO: (state, info) => {
      state.portalsInfo = info
    },
    SET_AUTHFLAG: (state, authFlag) => {
      state.authFlag = authFlag
    }
  },

  actions: {}
}

export default portalsUser
